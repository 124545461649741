import ApiConfig from './ApiConfig'
import {
  AlertClient_V1,
  //EmailClient_V1,
  GenderClient_V1,
  //HonorificClient_V1,
  LicenseClient_V1,
  //NameSuffixClient_V1,
  NameTypeClient_V1,
  //PhoneClient_V1,
  RaceClient_V1,
} from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGenders() {
      const api = new GenderClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getGenders()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRaces() {
      const api = new RaceClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getRaces()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNameTypes() {
      const api = new NameTypeClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getNameTypes()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /*
    fetchNameSuffixes() {
      const api = new NameSuffixClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getNameSuffixes()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHonorifics() {
      const api = new HonorificClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getHonorifics()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */
    fetchLicenses() {
      const api = new LicenseClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getLicenses()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /*
    fetchEmails() {
      const api = new EmailClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getEmails()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhones() {
      const api = new PhoneClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPhones()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */
    fetchAlerts() {
      const api = new AlertClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getAlerts()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
