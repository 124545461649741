<template>
  <div id="user-list">
    <!-- dialog -->
    <user-add-edit
      v-model="isUserFormActive"
      :is-edit="isEdit"
      :is-new="isNew"
      :user-d-t-o="selectedItem"
      @close-form="updateDialog"
      @refetch-data="refreshPage"
    ></user-add-edit>

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="refreshPage"
    ></delete-confirmation>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isUserFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="openDialog()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New User</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="userFields"
        :items="siteUserList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="initiateRevoke2fa(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Revoke MFA for this site</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="resetPassword(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFormTextboxPassword }}
                  </v-icon>
                  <span>Reset Password</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="initiateRevokeUserAccess(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Revoke Access to this site</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="beginUserUpdate(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit This User</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFormTextboxPassword,
} from '@mdi/js'

import userService from '@/services/UserService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useUserList from '@/store/useUserService'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import CommonList from '@/store/CommonService'
import UserAddEdit from './UserAddEdit.vue'

export default {
  components: { UserAddEdit, DeleteConfirmation },
  setup() {
    const AUTH_APP = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP)) {
      store.registerModule(AUTH_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const { requestPasswordResetEmail, userResponse, revokeMfa, revokeUserAccess, updateGivenUser } =
      useAuthenticationService()

    const {
      siteUserList,
      userFields,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
      userPostResponse,

      fetchSiteUsers,
      readLicense,
      readEmail,
      readPhone,
      readSite,
      readRole,
    } = useUserList()

    const USER_APP = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP)) store.registerModule(USER_APP, userService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP)) store.unregisterModule(USER_APP)
    })

    const { readStatus } = CommonList()

    const isEdit = ref(false)
    const isNew = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isUserFormActive = ref(false)

    return {
      siteUserList,
      userFields,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
      isUserFormActive,
      selectedItem,
      isEdit,
      isNew,
      isDeleteDialogActive,
      userPostResponse,

      updateGivenUser,
      revokeUserAccess,
      revokeMfa,
      fetchSiteUsers,
      readLicense,
      readEmail,
      readPhone,
      readSite,
      readRole,
      readStatus,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiFormTextboxPassword,
      },

      requestPasswordResetEmail,
      userResponse,
    }
  },
  methods: {
    openDialog() {
      this.isNew = true
      this.isEdit = false
      this.isUserFormActive = true
    },
    beginUserUpdate(item) {
      this.selectedItem = item
      this.isEdit = true

      this.isNew = false
      this.isUserFormActive = true
    },
    selectItem(item) {
      this.selectedItem = item
    },
    submitDelete(userDTO) {
      this.initiateRevokeUserAccess(userDTO.id)
    },
    resetPassword(userDTO) {
      this.requestPasswordResetEmail(userDTO.email)
    },
    refreshPage() {
      this.selectedItem = ref()
      this.fetchSiteUsers()
    },
    updateDialog(status) {
      if (status == null) {
        this.selectedItem = {}
      }
      this.isUserFormActive = !this.isUserFormActive
    },
    initiateRevokeUserAccess(item) {
      this.revokeUserAccess(item.id)
    },
    initiateRevoke2fa(item) {
      this.revokeMfa(item.id)
    },
  },
}
</script>
