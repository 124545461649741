import moment from 'moment'

export default function CommonService() {
  const readStatus = status => {
    if (status === true) {
      return 'Active'
    }

    return 'Inactive'
  }

  const formatDate = date => {
    let dates = moment(String(date)).format('YYYY-MM-DD')

    if (dates === 'Invalid date') {
      dates = null
    }

    return dates
  }

  const updateDialog = (dialogName, status) => {
    // //console.log('Status', status)
    // //console.log('Dialog', this.isDialogVisible)
    if (status == null) {
      this.selectedItem = {}
    }
    this.isDialogVisible = !this.isDialogVisible

    return this.isDialogVisible
  }

  const calculateAge = dob => {
    const currentDate = new Date()
    const difference = currentDate - new Date(dob)
    const age = Math.floor(difference / 31557600000)

    return age
  }

  return {
    readStatus,
    formatDate,
    updateDialog,
    calculateAge,
  }
}
