import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('user-add-edit',{attrs:{"is-edit":_vm.isEdit,"is-new":_vm.isNew,"user-d-t-o":_vm.selectedItem},on:{"close-form":_vm.updateDialog,"refetch-data":_vm.refreshPage},model:{value:(_vm.isUserFormActive),callback:function ($$v) {_vm.isUserFormActive=$$v},expression:"isUserFormActive"}}),_c('delete-confirmation',{attrs:{"selected-item":_vm.selectedItem},on:{"delete-confirmation":_vm.submitDelete,"refetch-data":_vm.refreshPage},model:{value:(_vm.isDeleteDialogActive),callback:function ($$v) {_vm.isDeleteDialogActive=$$v},expression:"isDeleteDialogActive"}}),_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialog()}},model:{value:(_vm.isUserFormActive),callback:function ($$v) {_vm.isUserFormActive=$$v},expression:"isUserFormActive"}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New User")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.userFields,"items":_vm.siteUserList,"options":_vm.options,"search":_vm.searchQuery,"loading":_vm.loading,"group-by":"isActive","group-desc":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.selectedItem = item}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.initiateRevoke2fa(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Revoke MFA for this site")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.resetPassword(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFormTextboxPassword)+" ")]),_c('span',[_vm._v("Reset Password")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.initiateRevokeUserAccess(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Revoke Access to this site")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.beginUserUpdate(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountEditOutline)+" ")]),_c('span',[_vm._v("Edit This User")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }