import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useDemographicsList() {
  const genderList = ref([])
  const raceList = ref([])
  const licenseList = ref([])
  const nameTypeList = ref([])
  const nameSuffixList = ref([])
  const honorificList = ref([])
  const emailList = ref([])
  const phoneList = ref([])
  const alertList = ref([])

  const loading = ref(false)
  const options = ref({
    sortBy: ['display'],
    sortDesc: [false],
    statusFilter: [true],
  })
  const selectedGender = ref([])
  const selectedRace = ref([])
  const selectedLicense = ref([])
  const selectedEmail = ref([])
  const selectedPhone = ref([])
  const selectedNameType = ref([])
  const selectedNameSuffix = ref([])
  const selectedHonorific = ref([])
  const searchQuery = ref('')

  const alertColumns = [
    { text: 'ALERT', value: 'alertName' },
    { text: 'PRIORITY', value: 'alertPriority' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  // fetch genders
  const fetchGenders = () => {
    store
      .dispatch('app-demographics/fetchGenders')
      .then(response => {
        genderList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchGenders due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedGender.value = []
    fetchGenders()
  })

  // fetch races
  const fetchRaces = () => {
    store
      .dispatch('app-demographics/fetchRaces')
      .then(response => {
        raceList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchRaces due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedRace.value = []
    fetchRaces()
  })

  // fetch name types
  const fetchNameTypes = () => {
    store
      .dispatch('app-demographics/fetchNameTypes')
      .then(response => {
        nameTypeList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchNameTypes due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedNameType.value = []
    fetchNameTypes()
  })

  // fetch name suffixes
  const fetchNameSuffixes = () => {
    store
      .dispatch('app-demographics/fetchNameSuffixes')
      .then(response => {
        nameSuffixList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchNameSuffixes due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedNameSuffix.value = []
    fetchNameSuffixes()
  })

  // fetch honorifics
  const fetchHonorifics = () => {
    store
      .dispatch('app-demographics/fetchHonorifics')
      .then(response => {
        honorificList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchHonorifics due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedHonorific.value = []
    fetchHonorifics()
  })

  // fetch licenses
  const fetchLicenses = () => {
    store
      .dispatch('app-demographics/fetchLicenses')
      .then(response => {
        licenseList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchLicenses due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedLicense.value = []
    fetchLicenses()
  })

  // fetch emails
  const fetchEmails = () => {
    store
      .dispatch('app-demographics/fetchEmails')
      .then(response => {
        emailList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchEmails due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedEmail.value = []
    fetchEmails()
  })

  // fetch phones
  const fetchPhones = () => {
    store
      .dispatch('app-demographics/fetchPhones')
      .then(response => {
        phoneList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchPhones due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedPhone.value = []
    fetchPhones()
  })

  // fetch alerts
  const fetchAlerts = () => {
    store
      .dispatch('app-demographics/fetchAlerts')
      .then(response => {
        alertList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchAlerts due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    fetchAlerts()
  })

  const readLicense = licenses => {
    const userLicenses = new Array('None')

    if (licenses !== undefined) {
      licenses.forEach((license, index) => {
        if (index >= 0) {
          userLicenses[index] = license.license
        }
      })
    }

    return userLicenses
  }

  const readEmail = emails => {
    const userEmails = new Array('None')

    emails.forEach((email, index) => {
      if (email !== undefined && email.id != null) {
        userEmails[index] = email.email
      }
    })

    return userEmails
  }

  const readPhone = phones => {
    const userPhones = new Array('None')

    phones.forEach((phone, index) => {
      if (phone !== undefined && phone.id != null) {
        userPhones[index] = phone.phone
      }
    })

    return userPhones
  }

  const readAlert = alerts => {
    const patientAlerts = new Array('None')

    // //console.log(alerts)

    if (alerts !== undefined && alerts !== null) {
      alerts.forEach((alert, index) => {
        if (index >= 0) {
          patientAlerts[index] = alert.alert
        }
      })
    }

    return patientAlerts
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    loading,
    options,

    genderList,
    selectedGender,
    fetchGenders,

    selectedRace,
    raceList,
    fetchRaces,

    selectedNameType,
    nameTypeList,
    fetchNameTypes,

    selectedLicense,
    licenseList,
    fetchLicenses,
    readLicense,

    selectedEmail,
    emailList,
    fetchEmails,
    readEmail,

    selectedPhone,
    phoneList,
    fetchPhones,
    readPhone,

    searchQuery,

    nameSuffixList,
    selectedNameSuffix,
    fetchNameSuffixes,

    selectedHonorific,
    honorificList,
    fetchHonorifics,

    alertList,
    alertColumns,
    fetchAlerts,
    readAlert,
  }
}
