import variables from '@/variables'
import ApiConfig from './ApiConfig'
import { AuthClient_V1 } from './generated/api.generated.v1'

export default {
  namespaced: true,
  actions: {
    updateGivenUser(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .updateUser(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getRolePackageArray() {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getRolePackageList()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // JWT
    mFASetup(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .mFASetup(payload)
          .then(response => resolve(response)) // we receive a TokensDTO which contains a Token (this is the jwt) and the RefreshToken
          .catch(error => reject(error))
      })
    },

    mFALogin(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .mFALogin(payload)
          .then(response => resolve(response)) // we receive a TokensDTO which contains a Token (this is the jwt) and the RefreshToken
          .catch(error => reject(error))
      })
    },

    login(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      // the jwt contains userid in the Sub, and Expiration string in the Exp, and the SiteId in the NameId field.
      // We should parse and store this information to be used when calling our API.
      return new Promise((resolve, reject) => {
        api
          .login(payload)
          .then(response => resolve(response)) // we receive a TokensDTO which contains a Token (this is the jwt) and the RefreshToken
          .catch(error => reject(error))
      })
    },

    authAttempt(context, authClaimDTO) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .authAttempt(authClaimDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    registerUser(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      // console.log(`payload: ${payload}`)

      return new Promise((resolve, reject) => {
        api
          .newUser(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    requestPasswordResetEmail(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .requestPasswordResetEmail(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    resetPassword(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .resetPassword(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updatePassword(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .updatePassword(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    confirmEmail(context, EntryDTO) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .confirmEmailAddress(EntryDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    oTPLogin(context, payload) {
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .oTPLogin(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updatePhoneNumber(context, payload) {
      console.log(payload)
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .updatePhoneNumber(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    revokeMfa(context, payload) {
      console.log(payload)
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .revokeMfa(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    revokeUserAccess(context, payload) {
      console.log(payload)
      const api = new AuthClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .revokeUserAccess(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
