<template>
  <div id="license-select">
    <v-combobox
      v-if="isEdit"
      v-model="selectedLicenses"
      :items="licenseList"
      item-text="licenseName"
      outlined
      label="Select License(s)"
      clearable
      chips
      dense
      multiple
      @change="selectLicense(selectedLicenses)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import demographicsService from '@/services/DemographicsService'
import useDemographicsList from '@/store/useDemographicsService'

export default {
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
  setup(props, { emit }) {
    const DEMOGRAPHICS_APP = 'app-demographics'

    // Register module
    if (!store.hasModule(DEMOGRAPHICS_APP)) {
      store.registerModule(DEMOGRAPHICS_APP, demographicsService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMOGRAPHICS_APP)) {
        store.unregisterModule(DEMOGRAPHICS_APP)
      }
    })

    const { searchQuery, licenseList, fetchLicenses, readLicense } = useDemographicsList()

    const selectedLicenses = readLicense(props.user.userLicenses)

    const selectLicense = items => {
      emit('license-select', items)
    }

    return {
      licenseList,
      selectedLicenses,
      fetchLicenses,
      readLicense,
      searchQuery,

      selectLicense,
    }
  },
  mounted() {
    this.fetchLicenses()
  },
}
</script>
