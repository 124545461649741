<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isUserFormActive"
        max-width="75%"
      >
        <v-card
          class="mx-auto"
          width="100%"
          max-width="400"
        >
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <v-avatar
              color="primary lighten-2"
              class="subheading white--text"
              size="24"
              v-text="step"
            ></v-avatar>
          </v-card-title>
          <v-form
            ref="registerForm"
          >
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                  <v-text-field
                    v-model="userDTO.prefix"
                    label="Prefix: Ms., Mrs., Mr., etc "
                    :readonly="isEdit"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="userDTO.suffix"
                    label="Suffix: I, II, III, etc"
                    :readonly="isEdit"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="userDTO.firstName"
                    label="First Name"
                    :readonly="isEdit"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="userDTO.lastName"
                    label="Last Name"
                    :readonly="isEdit"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="userDTO.phoneNumber"
                    label="Phone Number: 1 1239876540"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="2">
                <v-card-text>
                  <v-select
                    v-model="userDTO.rolePackage"
                    :items="rolePackageArray"
                    label="Position"
                  >
                  </v-select>
                  <v-text-field
                    v-model="userDTO.email"
                    :rules="emailRules"
                    :readonly="isEdit"
                    label="Email"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="userDTO.deaNumber"
                    label="DEA Number"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="userDTO.npiNumber"
                    label="NPI Number"
                    required
                  ></v-text-field>
                  <site-select
                    :site="site"
                    @site-select="updateSite"
                  >
                  </site-select>
                  <user-license-select
                    v-if="isEdit"
                    :is-edit="isEdit"
                    :user="userDTO"
                    @license-select="updateLicenses"
                  ></user-license-select>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="3">
                <div class="pa-4 text-center">
                  <v-img
                    class="mb-4"
                    contain
                    height="128"
                    src="https://www.svgrepo.com/show/43086/success.svg"
                  ></v-img>
                  <h3 class="title font-weight-light mb-2">
                    Welcome to DetainEHR
                  </h3>
                  <span class="caption grey--text">We've sent a confirmation link to the new user's email address, it needs to be confirmed prior to logging in</span>
                </div>
              </v-window-item>
            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                text
                @click.prevent="close()"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="step === 1"
                text
                @click="step--"
              >
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="step === 3"
                color="primary"
                depressed
                @click="formSubmit(userDTO)"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useAuthenticationService from '@/store/useAuthenticationService'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import useUserList from '@/store/useUserService'
import UserLicenseSelect from '@/components/user/UserLicenseSelect.vue'
import SiteSelect from '@/components/site/SiteSelect.vue'

export default {
  components: {
    UserLicenseSelect,
    SiteSelect,
  },
  model: {
    prop: 'isUserFormActive',
    event: 'update:close-form',
  },
  props: {
    isUserFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: true,
    },
    userDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rolePackageArray: [
        'Medical technician',
        'LPN',
        'Practioner',
        'Prescriber',
        'Medical Records',
        'Nurse',
        'Site Staff',
        'Read Only',
        'Mental Health',
        'Offline MAR',
        'Dental Assistant',
      ],
      site: null,
      siteId: '',
      globalUser: false,
      registerSiteId: '',
      step: 1,
      rolePackage: '',
      modificationsMade: 'Registered New User',
      firstName: '',
      lastName: '',
      suffix: '',
      prefix: '',
      title: '',
      honorific: '',
      npiNumber: '',
      deaNumber: '',
      profilePicture: '',
      phoneNumber: '',
      twoFactorEnabled: false,
      recordedOn: Date.now(),
      recordedBy: 'Site Admin',
      email: '',
      emailRules: [
        v => !!v || 'Email is required',
        v => v.length >= 5 || 'The e-mail address must contain at least 5 characters',
        v => v.length <= 50 || 'The e-mail address cannot be longer than 50 characters',
        v => /.+@.+/.test(v) || 'Please enter a valid email address',
      ],
    }
  },
  setup(props, { emit }) {
    const { userExists } = props.userDTO
    const { newUser } = props.isNew
    console.table(userExists)
    const userForm = ref(null)
    const {
      postUserSite,
      postUserRole,
      postUserLicense,
      readLicense,
      readEmail,
      readPhone,
      readSite,
      readRole,
      postUserEmail,
      postUserPhone,
    } = useUserList()

    const validate = userDTO => {
      if (userForm.value.validate(userDTO)) {
        return true
      }

      return false
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('close-form', false)
    }

    const AUTH_APP = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP)) store.registerModule(AUTH_APP)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const { registerUser, userResponse, errorMessages, updateGivenUser } = useAuthenticationService()

    return {
      newUser,
      updateGivenUser,
      userExists,
      userForm,
      validate,
      closeForm,
      refreshParent,

      postUserSite,
      postUserRole,
      postUserLicense,
      postUserEmail,
      postUserPhone,
      readLicense,
      readEmail,
      readPhone,
      readSite,
      readRole,

      registerUser,
      userResponse,

      errorMessages,

      required,

      icons: {
        mdiClose,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Employee Information'
        case 2:
          return 'Employee Details'
        default:
          return 'Account created'
      }
    },
  },
  methods: {
    updateNameType(nameTypeDTO) {
      // console.log(nameTypeDTO)
      this.userDTO.nameTypeId = nameTypeDTO.value.id
    },

    updateNameSuffix(nameSuffixDTO) {
      // console.log(nameSuffixDTO)
      this.userDTO.nameSuffixId = nameSuffixDTO.value.id
    },
    updateSite(siteDTO) {
      this.site = siteDTO.value
      this.siteId = siteDTO.value.id
    },

    updateLicenses(licenseDTOs) {
      // console.log(licenseDTOs)
      this.userLicenseDTO.splice(0)
      licenseDTOs.forEach(licenseDTO => {
        this.userLicenseDTO.push({
          inactivatedBy: null,
          inactivatedDate: null,
          isActive: true,
          licenseId: licenseDTO.id,
          userId: this.userDTO.id,
        })
      })

      this.userDTO.userLicenses.forEach(userLicense => {
        if (
          !this.userLicenseDTO.find(ul => ul.licenseId === userLicense.licenseId) &&
          userLicense.licenseId !== undefined
        ) {
          this.userLicenseDTO.push({
            inactivatedBy: null,
            inactivatedDate: null,
            isActive: false,
            licenseId: userLicense.licenseId,
            userId: this.userDTO.id,
          })
        }

        return userLicense
      })

      // console.log(this.userDTO)
    },
    close() {
      location.reload()
    },
    async formSubmit() {
      if (this.step < 3) this.step++

      if (this.step === 3) {
        if (this.isEdit && !this.newUser) {
          await this.updateGivenUser(this.userDTO)

          this.close()
        } else {
          this.userDTO.aPIKey = null
          this.userDTO.inactivatedBy = null
          this.userDTO.inactivatedDate = null
          this.userDTO.recordedDate = new Date().toISOString().substr(0, 10)
          this.userDTO.modifiedDate = new Date().toISOString().substr(0, 10)
          await this.registerUser(this.userDTO)

          await this.userDTO.userLicenses.forEach(userLicense => {
            this.postUserLicense(userLicense)
          })

          this.close()
        }
      }
    },
  },
}
</script>
