<template>
  <div id="site-select">
    <v-combobox
      v-model="selectedSite"
      :items="siteListTable"
      item-text="display"
      outlined
      label="Select Site"
      clearable
      @change="selectSite(selectedSite)"
    >
    </v-combobox>
    <v-row
      v-if="siteListTable.length === 1"
    >
      <v-overlay
        value="true"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

import siteService from '@/services/SiteService'
import useSiteList from '@/store/useSiteService'

export default {
  props: {
    site: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  setup(props, { emit }) {
    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) store.registerModule(SITE_APP, siteService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) store.unregisterModule(SITE_APP)
    })

    const { siteListTable, fetchSites } = useSiteList()

    const selectedSite = ref(props.site)

    const selectSite = () => {
      emit('site-select', selectedSite)
    }

    // const log = msg => {
    //   console.log(msg)
    // }

    return {
      siteListTable,
      selectSite,
      selectedSite,
      fetchSites,

      // log,
    }
  },
  mounted() {
    this.fetchSites()
  },
}
</script>
