import ApiConfig from './ApiConfig'
import {
  //EmailClient_V1,
  LicenseClient_V1,
  UserClient_V1,
} from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // USERS

    fetchUsers() {
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getUsers()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUser(context, userId) {
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getUser(userId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postUser(context, userDTO) {
      const userId = '6808891f-4518-4a65-bb63-10d7989cb125' // Change to userData.id
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postUser(userId, userDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteUser(context, userId) {
      // const userData = JSON.parse(localStorage.getItem('userData')) // Uncomment

      // const userId = '6808891f-4518-4a65-bb63-10d7989cb125' // Change to userData.id
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deleteUser(userId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // USER SITES

    fetchSiteUsers() {
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteUsers()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postUserSite(context, payload) {
      // const userData = JSON.parse(localStorage.getItem('userData')) // Uncomment
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postUserSite(payload.userDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteSiteUser(context, userSiteId) {
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deleteUserSite(userSiteId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // USER ROLES
    /*
    fetchUserRoles(context, userId) {
      const api = new UserRoleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      const siteId = 'e4e3ae09-3129-4278-a345-0ea2fed6374b'

      return new Promise((resolve, reject) => {
        api
          .getUserRoles(userId, siteId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    postUserRole(context, payload) {
      console.log(payload)
      const api = new UserRoleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postUserRole(payload.userRole)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */

    // USER LICENSES

    postUserLicense(context, userLicenseDTO) {
      const api = new LicenseClient_V1(new ApiConfig(), variables.INTERNAL_API)
      console.log(userLicenseDTO)

      return new Promise((resolve, reject) => {
        api
          .postUserLicense(userLicenseDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // USER EMAILS
    /*
    postUserEmail(context, payload) {
      const api = new EmailClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postUserEmail(payload.userId, payload.emailDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */
  },
}
