import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUserList() {
  const siteUserList = ref([])
  const userPostResponse = ref([])
  const userLicensePostResponse = ref([])

  // const userEmailPostResponse = ref([])
  // const userPhonePostResponse = ref([])
  const userList = ref([])
  const userData = ref({
    inactivatedBy: null,
    inactivatedDate: null,
  })

  const userFields = [
    { text: 'FIRST NAME', value: 'firstName' },
    { text: 'LAST NAME', value: 'lastName' },
    { text: 'USER NAME', value: 'userName' },
    { text: 'EMAIL', value: 'email' },
    { text: 'PHONE', value: 'phoneNumber' },
    { text: 'POSITION', value: 'rolePackage' },
    { text: 'ACTIVE?', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const sortKeys = ref(['firstName', 'lastName', 'birthDate', 'genderId', 'raceId', 'isActive'])
  const searchQuery = ref('')
  const statusFilter = ref(null)
  const genderFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['UserName'],
    sortDesc: [false],
    statusFilter: [true],
  })
  const userTotalLocal = ref([])
  const selectedItem = ref([])

  // fetch data
  const fetchSiteUsers = () => {
    store
      .dispatch('app-user/fetchSiteUsers')
      .then(response => {
        siteUserList.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteUsers due to ${error}`)
      })
  }

  watch([searchQuery, statusFilter, options, genderFilter], () => {
    loading.value = true
    selectedItem.value = []
    fetchSiteUsers()
  })

  // fetch data
  const fetchUser = async userId => {
    await store
      .dispatch('app-user/fetchUser', { userId })
      .then(response => {
        siteUserList.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteSettings due to ${error}`)
      })
  }

  const postUserLicense = async userLicense => {
    await store
      .dispatch('app-user/postUserLicense', userLicense)
      .then(response => {
        userLicensePostResponse.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`error postUserLicense due to ${error}`)
      })
  }

  const postUserSite = async userDTO => {
    const userPayload = { userDTO }

    await store
      .dispatch('app-user/postUserSite', userPayload)
      .then(response => {
        userPostResponse.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`error postUserPhone due to ${error}`)
      })
  }

  // Disable User from All Sites
  const deleteUser = async userId => {
    await store
      .dispatch('app-user/deleteUser', userId)
      .then(response => {
        userPostResponse.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`error deleteUser due to ${error}`)
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Inactive') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Active users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const readLicense = licenses => {
    const userLicenses = new Array('None')

    if (licenses !== undefined) {
      licenses.forEach((license, index) => {
        if (license !== undefined && license.id != null) {
          userLicenses[index] = license.license.licenseName
        }
      })
    }

    return userLicenses
  }

  const readEmail = emails => {
    const userEmails = new Array('None')

    if (emails !== undefined) {
      emails.forEach((email, index) => {
        if (email !== undefined && email.id != null) {
          userEmails[index] = email.email.emailAddress
        }
      })
    }

    return userEmails
  }

  const readPhone = phones => {
    const userPhones = new Array('None')

    if (phones !== undefined) {
      phones.forEach((phone, index) => {
        if (phone !== undefined && phone.id != null) {
          userPhones[index] = phone.phone.phoneNumber
        }
      })
    }

    return userPhones
  }

  const readSite = sites => {
    const userSites = new Array('None')

    if (sites !== undefined) {
      sites.forEach((site, index) => {
        if (site !== undefined && site.id != null) {
          userSites[index] = site.site.display
        }
      })
    }

    return userSites
  }
  const readRole = roles => {
    const userRoles = new Array('None')

    if (roles !== undefined) {
      roles.forEach((role, index) => {
        if (role !== undefined && role.id != null) {
          userRoles[index] = role.role.display
        }
      })
    }

    return userRoles
  }

  return {
    siteUserList,
    searchQuery,
    statusFilter,
    genderFilter,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    selectedItem,
    userList,
    sortKeys,
    userFields,
    userPostResponse,

    userData,
    fetchSiteUsers,
    fetchUser,
    postUserSite,
    postUserLicense,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    deleteUser,

    readLicense,
    readEmail,
    readPhone,
    readSite,
    readRole,
  }
}
